<template>
  <!-- table section -->
  <div class="warranty-request-table">
    <b-table
      v-if="warrantyRequests"
      :items="warrantyRequests"
      :fields="warrantyRequestsFields"
      :small="true"
      :busy="onLoading"
      responsive
      bordered
      hover
      show-empty
      empty-text="Không tìm thấy dữ liệu !!"
    >
      <template v-slot:table-busy>
        <vcl-table :speed="5" :animate="true" :columns="10" />
      </template>

      <template #cell(billNumber)="data">
        <div
          class="d-flex flex-column justify-content-center align-items-center"
        >
          <span v-b-tooltip.hover title="Chi tiết hoá đơn">
            <span
              @click="editBills(data.item.billNumber)"
              class="
                  text-link
                  text-primary
                  d-block
                  cursor-pointer
                  font-weight-bolder
                  mb-1
                  mt-3
                "
            >
              {{ data.item.billNumber }}
            </span>
          </span>

          <i class="fa fa-link text-warning text-sm" aria-hidden="true"></i>

          <p class="mt-1" style="font-size: 1rem">{{ data.item.id }}</p>
        </div>
      </template>

      <template #cell(storeName)="data">
        <b class="text-left pull-left">{{ data.item.storeName }}</b>
      </template>

      <template #cell(createdAt)="data">
        <span class="d-block mb-2">{{
          data.item.createdAt
        }}</span>
      </template>

      

      <template #cell(productName)="data">
        <div class="mt-2" v-if="data.item.productName">
          <span class="d-block" v-if="data.item.barCode"
            >({{ data.item.barCode }})</span
          >
          <span class="d-block font-weight-bolder">
            {{ data.item.productName }}
          </span>
          <span v-if="data.item.imeiNo">
            <p>
              <i class="text-primary" style="font-size: 12px"
                >IMEI: {{ data.item.imeiNo }}</i
              >
            </p>
          </span>
        </div>
      </template>

      <template #cell(status)="data">
        <span
          v-text="data.item.status"
          style="width: max-content"
          v-bind:class="{
            'badge badge-success text-white':
              data.item.status === 'Đã đặt lịch BH',
            'badge badge-dark': data.item.status === ''
          }"
        ></span>
      </template>
      <template #cell(customerName)="data">
        <b class="text-">{{ data.item.customerName }}</b>
        <span class="cursor-pointer d-block mb-1">
          {{ data.item.customerMobile }}
        </span>
        <div
          v-if="
            data.item.customerAddress &&
              data.item.customerAddress.trim().length > 0
          "
          class="mb-1"
        >
          <b>ĐC:</b>
          {{ data.item.customerAddress }}
        </div>
      </template>
      <template #cell(warrantyNote)="data">
        <p class="dashed text">{{ data.item.warrantyNote }}</p>
      </template>
      <template #cell(actions)="">
        <template slot="button-content">
          <i style="font-size: 1rem" class="flaticon2-settings pr-0"></i>
        </template>
      </template>
    </b-table>
  </div>
  <!-- end table section--->
</template>

<script>
import { VclTable } from 'vue-content-loading';
import { formatDate } from '@/utils/common';

export default {
  components: {
    VclTable
  },
  props: ['warrantyRequests'],
  data() {
    return {
      onLoading: false,
      warrantyRequestsFields: [
        {
          key: 'billNumber',
          label: 'Mã phiếu',
          tdClass: 'text-center',
          thStyle: {
            width: '12%'
          }
        },
        {
          key: 'storeName',
          label: 'Cửa hàng',
          thStyle: {
            width: '10%'
          }
        },
        {
          key: 'createdAt',
          label: 'Thời gian khách yêu cầu',
          thStyle: {
            width: '10%'
          }
        },
        {
          key: 'customerName',
          label: 'Thông tin khách hàng',
          thStyle: {
            width: '13%'
          },
          tdClass: 'text-wrap'
        },

        {
          key: 'productName',
          label: 'Sản phẩm'
        },
        {
          key: 'warrantyNote',
          label: 'Ghi chú',
          thStyle: {
            width: '15%'
          }
        },
        {
          key: 'status',
          label: 'Trạng thái',
          tdClass: 'text-center'
        },
        {
          key: 'actions',
          label: ' ',
          tdClass: 'text-center',
          thStyle: {
            width: '5%'
          }
        }
      ]
    };
  },
  methods: {
    formatDate,
    formatProductName(data) {
      return [data.productName, data.size, data.color]
        .filter(item => item)
        .join(' - ');
    },
    editBills(billNumber) {
      this.$router.push({
        name: 'update-bill',
        query: { id: billNumber }
      });
    }
  }
};
</script>

<style lang="scss">
.warranty-request-table {
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #ecf0f3;
  }

  .table thead tr > th {
    text-align: center;
  }
  .text-link:hover {
    text-decoration: underline;
  }
  .dashed {
    border: 1px dashed #464e5f;
    border-style: none none dashed;
    color: #464e5f;
  }
  .pre-line {
    white-space: pre-line;
  }
}
</style>
